import React from 'react'
import { Grid, DisplayItem } from '../generic'
import { printCurrency, printDate } from '../../lib/helpers'

// TODO: Add PropTypes

const PolicyInformation = (props) => {

  const { creditlife: { policyNumber, payment, coverAmount, quoteAcceptedDate, applicationDate, termsVersion, productType, signupSalesInteraction, accountingIdentifier }, quote = true, setProductType } = props

  let salesInteraction;
  if (signupSalesInteraction) {
    if (signupSalesInteraction.salesPerson) {
      salesInteraction = `${signupSalesInteraction.salesPerson.first} ${signupSalesInteraction.salesPerson.last}`;
    } else {
      salesInteraction = 'SALES';
    }
  } else if (accountingIdentifier) {
    salesInteraction = accountingIdentifier;
  } else {
    salesInteraction = 'DIGITAL SALE';
  }

  return (
    <>
      <h2>Product Information</h2>
      <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
        <DisplayItem label="Product Type" value={productType} xs={2} />
        <DisplayItem label="Terms Version" value={termsVersion} xs={2} />
        <DisplayItem label="Applied" value={printDate(applicationDate)} xs={2} />
        <DisplayItem label="Accepted" value={printDate(quoteAcceptedDate)} xs={2} />
        <DisplayItem label="Cover Amount" value={printCurrency(coverAmount)} xs={2} />
        <DisplayItem label="Payment" value={printCurrency(payment)} xs={2} />
        <DisplayItem label="Sold By" value={salesInteraction} xs={2} />
      </Grid>
      <br />
    </>
  )
}

export default PolicyInformation
// < h1 > { quote? 'Quote': 'Policy' } Information</h1 >
