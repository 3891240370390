import React, { useState, useEffect } from 'react'
import { graphql, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom'
import { useConfirm } from "material-ui-confirm";
import { ActivityIndicator, Button, FormModal, Grid, DisplayItem, DisplayFile } from '../../generic'
import moment from 'moment';
import { printCurrency } from '../../../lib/helpers'
import { IdTypes } from '../../../variables/IdTypes'
import EditClient from '../../clients/EditClient';
import EditClaimant from '../../claims/EditClaimant';
// import Member from '../..//funeral/Member';

import { a_selfie, id_card } from '../../../assets/images';

export const GET_CLIENT = gql`
	query Client($id: String!) {
		client(id: $id) {
    id
    firstNames
    employmentStatus
    occupation
    surname
    idType
    idNumber
    passportExpiresDate
    cellNumbers
    whatsAppNumber
    workPhone
    homePhone
    email
    smoker
    income
    estimatedIncome
    acceptedTerms
    createdAt
    dead
    dateOfDeath
    deathPlace
    causeOfDeath
    creditRecord {
      fetchedAt
    }
    creditLifePolicies {
      id
    }
    funeralPolicies {
      id
    }
    savings {
      id
    }
    documents {
      id
      name
      type
      description
      file {
        id
        url
        contentType
      }
      verified
    }
  }
}`

const EditModal = (props) => {
  const { size } = props
  return (
    <FormModal
      okText="Ok"
      cancelText="Back"
      width='90%'
      disableCancel={true}
      disableOk={true}
      size={size}
      componentProps={props}
    />
  )
}

const getValue = (label) => {
  if (label) {
    const value = IdTypes.find(x => x.label === label)
    return value ? value.value : ''
  }
  return null
}

const getLabel = (value) => {
  if (value) {
    const label = IdTypes.find(x => x.value === value)
    return label ? label.label : ''
  }
  return null
}

const FETCH_CREDIT_RECORD = gql`
	query FetchCompuscanCreditRecord ($firstName: String, $lastName: String, $idNumber: String, $forceCheck: Boolean, $compuscore: Boolean, $useBalanceAccount: Boolean) {
		fetchCompuscanCreditRecord(firstName: $firstName, lastName: $lastName, idNumber: $idNumber, forceCheck: $forceCheck, compuscore: $compuscore, useBalanceAccount: $useBalanceAccount  ) {
      fetchedAt
		}
	}
`;

const fileInfo = (type, documents) => {
  if (documents) {
    const doc = documents && documents.filter(d => (d.type && d.type.toUpperCase() === type.toUpperCase()) && d.file && d.verified === true).slice(-1)[0]
    return doc && doc.file ? { id: doc.id, fileId: doc.file.id, verified: doc.verified } : undefined
  }
  else return undefined
}

let ClientInformation = ({ id, data, client, clientType, forFuneral, productId, cancel, history, refetchQuery, title, relationship, claimId, allowEdit = true,...other }) => {
  const confirm = useConfirm();
  const { firstNames, surname, idNumber, email, cellNumbers, whatsAppNumber, documents, acceptedTerms, smoker, creditRecord, idType, passportExpiresDate, estimatedIncome, income, creditLifePolicies, funeralPolicies, savings, referredClients, createdAt, dead, dateOfDeath, deathPlace, causeOfDeath } = data.client || {}
  const [accepted, setAccepted] = useState(acceptedTerms)
  // const [open, setOpen] = useState(false)
  // const [file, setFile] = useState()
  const [fileName, setFileName] = useState()
  const [description, setDescription] = useState()
  const [idCard, setIdCard] = useState(fileInfo('ID', documents))
  const [selfie, setSelfie] = useState(fileInfo('SELFIE', documents))
  // const [vaccinatedUrl, setVaccinated] = useState(fileInfo('VACCINATION', documents))
  //const [wasVerified, setWasVerified] = useState(true)
  const [disableCompuscanCall, setDisableCompuscanCall] = useState(false)
  const [compuscoreResult, setCompuscoreResult] = useState(creditRecord || {})


  // useEffect(() => {
  //   setIdCard(fileInfo('ID', documents))
  //   setSelfie(fileInfo('SELFIE', documents))
  // }, [data.client]);

  const fetchCompuscanCreditRecord = () => {
    setDisableCompuscanCall(true)
    let input = { firstNames, surname, idNumber, forceCheck: true, compuscore: false, useBalanceAccount: true }
    client
      .query({
        query: FETCH_CREDIT_RECORD,
        variables: { ...input },
      })
      .then(res => {
        setCompuscoreResult(res.data.fetchCompuscanCreditRecord)
      })
  }

  const checkVerified = (verified) => {
    if (!verified) {
      if (fileName === 'ID') {
        setIdCardUrl()
      }
      else if (fileName === 'SELFIE') {
        setSelfieUrl()
      } 
    }
  }

  const goToClient = (idNumber) => {
    if (cancel) {
      cancel()
    }
    else {
      localStorage.setItem('searchString', idNumber);
      history.push(`/products/${idNumber}`)
    }
  }

  return (
    <>
      <Grid container justifyContent='flex-start' textAlign='center' spacing={2}>
        <Grid item xs={3} alignItems='center'>
          <EditModal Content={EditClient} noButton heading={`Change ${title || "Client"} Details`} text={title || "Client"} id={id} refetch={GET_CLIENT} clientType={clientType} claimId={claimId} allowEdit={allowEdit} />
        </Grid>
        <Grid item xs={7}>
        </Grid>
        <Grid item xs={1} >
          <DisplayFile key="SELFIE" clientInfo={data.client} clientDocumentId={selfie ? selfie.id : ''} verified={true} documentType='SELFIE' description="Selfie" fileId={selfie ? selfie.fileId : ''} noDelete={true} displayIfValid={true} onChange={(fileId) => setSelfie({ fileId })} />
        </Grid>
        <Grid item xs={1} >
          <DisplayFile key="ID" clientInfo={data.client} clientDocumentId={idCard ? idCard.id : ''} verified={true} documentType='ID' description="ID" fileId={idCard ? idCard.fileId : ''} noDelete={true} displayIfValid={true} onChange={(fileId) => setIdCard({ fileId })} />
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-start' alignItems='center' spacing={2}>
        {relationship && <EditModal Content={EditClaimant} displayItem heading={`Relationship to Member`} text={relationship} id={claimId} fontSize={16} fontWeight={400} />} 
        <DisplayItem label="First Name" value={firstNames} xs={2} />
        <DisplayItem label="Surname" value={surname} xs={2} />
        <DisplayItem label="Id Number" value={`${idType && idType !== 'rsa_id' ? idType : ''} ${idNumber} `} style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => goToClient(idNumber)} xs={2} />
        <DisplayItem type='mobile' clientId={id} label="Mobile" value={cellNumbers && cellNumbers !== null ? cellNumbers[0] ? cellNumbers[0] : '-' : ' '} />
        <DisplayItem type='text' clientId={id} label="WhatsApp" value={whatsAppNumber ? whatsAppNumber : ' '} />
        <DisplayItem label="Email" value={email ? email : ' '} xs={email ? 2 : 1} />
        <DisplayItem label="Created" value={moment(createdAt).format("Do MMMM YYYY")} />
        <DisplayItem label="T&C" value={accepted ? 'Yes' : accepted === false ? 'No' : 'No'} />
        {idType !== 'rsa_id' && idType !== 'SOUTH AFRICA' && <DisplayItem label="Name" value={passportExpiresDate ? moment(passportExpiresDate).format('DD-MM-YYYY') : '-'} />}
        <DisplayItem label="Smoker" value={smoker ? 'Yes' : smoker === false ? 'No' : '-'} />
        <DisplayItem label="Income" value={income ? printCurrency(income) : "-"} />
        <DisplayItem label="Est Income" value={printCurrency(estimatedIncome)} />
        <DisplayItem label="Credit Life" value={creditLifePolicies && creditLifePolicies.length > 0 ? "YES" : "NO"} />
        <DisplayItem label="Funeral" value={funeralPolicies && funeralPolicies.length > 0 ? "YES" : "NO"} />
        <DisplayItem label="Savings" value={savings ? "YES" : "NO"} />
        <DisplayItem label="Referrer" value={referredClients && referredClients.length > 0 ? "YES" : "NO"} />
        <DisplayItem label="Compuscan" value={compuscoreResult.fetchedAt ? moment(compuscoreResult.fetchedAt).format("Do MMMM YYYY") : 'Never retrieved'} />
        {accepted && <Grid item >
          <Button onClick={() => confirm({
            title: 'Get Client CompuScan record?', description: `Are you sure you want to call Compuscan? (This is allowed once an hour)`, cancellationButtonProps: { variant: 'contained', color: 'default' }, confirmationButtonProps: { variant: 'contained', color: 'primary' }
          }).then(() => fetchCompuscanCreditRecord())}
            disabled={disableCompuscanCall || (compuscoreResult.fetchedAt && moment().diff(moment(compuscoreResult.fetchedAt), 'minutes') < 60)}>Compuscan</Button>
        </Grid>}
        {dead &&
          <>
          <DisplayItem label="Dead" value={dead ? moment(dateOfDeath).format("Do MMMM YYYY") : " "} />
            <DisplayItem label="Place Of Death" value={dead ? deathPlace : " "} />
            <DisplayItem label="Cause" value={dead ? causeOfDeath : " "} />
          </>}
      </Grid>
      {/*
      <VerifyDoc
        file={file}
        description={description}
        setOpen={setOpen}
        open={open}
        setVerified={(verified) => { checkVerified(verified) }}
        clientData={data.client}
      /> */}

    </>)
}

const graphqlLoadingHOC = Comp => props => {

  if (props.data.loading) {
    return <ActivityIndicator />
  }

  if (props.data.error) {
    return <h1>{props.data.error.toString()}</h1>
  }

  return <Comp {...props} />
}

ClientInformation = withRouter(ClientInformation)
ClientInformation = withApollo(ClientInformation)
ClientInformation = graphqlLoadingHOC(ClientInformation)
ClientInformation = graphql(GET_CLIENT, {
  options: ({ match, id }) => ({ variables: { id: match && match.params ? match.params.id : id } })
})(ClientInformation)
export default ClientInformation;
