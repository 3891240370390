import React, { useState, useEffect, Fragment } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { useConfirm } from "material-ui-confirm";
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import CreditLifeQuote from './CreditLifeQuote'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { ActivityIndicator, Button, Card, Select, Grid, Modal, FormModal } from '../generic'
import Numeral from 'numeral'
import LoanObligations from './Loans'
import { onBasicList, searchList } from '../../lib/fuzzy';
import Information from './Information'
import PolicyInformation from './PolicyInformation'
import PaymentHistory from './PaymentHistory'
import MissingRequirements from './MissingRequirements'
import PolicyStatusHistory from './PolicyStatusHistory'
import GraceDays from './GraceDays'
import { ClientNotes, AllocationSummary, ClientInformation, ClientDocuments } from '../shared'
import ProductCommunication from '../communication/ProductCommunication'

import Selector from '../generic/Selector'
// import SelectProductType from './SelectProductType'
import BankAccount from '../bankAccount/BankAccount';
import Client from '../clients/Client';

Numeral.locale('en-za') // switch between locales

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    fontSize: 21,
    paddingBottom: 7,
    marginTop: 7
  },
  center: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    paddingBottom: 7
  },
  buttonRow: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    paddingTop: 7
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const HAZARDOUS =
  [{ name: "chair lift operator" },
    { name: "crane operator" },
    { name: "foreman" },
    { name: "med board pension" },
    { name: "miner" },
    { name: "mining operator" },
    { name: "operator" },
    { name: "owner" },
    { name: "pensioner" },
    { name: "private pensioner" },
    { name: "rock drill operator" },
    { name: "safety officer" },
    { name: "self employed" },
    { name: "electrician" },
    { name: "minesupervisor" }
  ]

const opts = {
  includeScore: true,
  includeMatches: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [
    "name"
  ]
}

export const creditLifeInformation = gql`
	query CreditLifeAndCompanies($id: String!) {
		creditlife(id: $id) {
    id
    policyNumber
    termsVersion
    pricingVersion
    payment
    coverAmount
    paymentStartDate
    productType
    signatureId
    actionDay
    paymentDay
    hasSignedDO
    debitOrderId
    beneficiaries {
      payTo {
        id
        firstNames,
        surname,
        idType,
        idNumber,
        cellNumbers,
        email,
        relationship
      }
    }
    paymentBankAccountId
    paymentBankAccount {
      id
      bankName
      branchCode
      accountNo
      ownAccount
      accountHolderName
      accountHolderContactNumber
      accountHolderIdNumber
      accountHolderEmail
      idNumberMatch
      nameMatch
      phoneMatch
      accountExists
      avsRawData
      }
    obligationInsurances {
      package
      rejectReason
      amount
      premiumAmount
      cancelledAt
      status
      statusAt
      obligationId
      obligationAccountHolder
      obligation {
        id
        externalId
        clientCreditor {
          id
          description
        }
        loanType
        reference
        accountCode
        inceptionDate
        currentBalance
        statusCode
        balances {
          date
          balance
          prevBalance
          prevBalanceDate
          balanceSource
          estimated
        }
        insuranceAmount
        creditLifeInsurance
        shortTermInsurance
      }
    }
    payments {
      paymentFor
      timestamp
      amount
      reversalReason
      # paymentType
    }
    policyStatusChanges {
      statusAfter
      updatedAt
    }
    allocationSummary {
      forMonth
      allocationType
      positive
      negative
      net
    }
    clientId
    client {
      id
      idType
      idNumber
      acceptedTerms
      workPhone
      employmentStatus
      debtCounselling {
        id
        debtCounsellor {
          id
          description
          shortName
        }
        actionDay
        proposalStatus
      }
      creditorObligations {
        id
        externalId
        clientCreditor {
          id
          description
        }
        loanType
        reference
        accountCode
        inceptionDate
        currentBalance
        balanceDate
        insuranceAmount
        creditLifeInsurance
        removedAt
        originalPayment {
          installment
          rate
        }
        removedAt
        source
      }
      employers {
        companyName
        occupation
        isPartTime
      }
    }
    secondaryApplicantId
    signupSalesInteraction {
      id
      salesPerson {
        id
        first
        last
        email
      }
    }
    reason
    policyStatus
    applicationDate
    links {
      code
      timesUsed
      redirect
    }
    quoteAccepted
    quoteAcceptedDate
    createdAt
    updatedAt
    graceDays
    graceDaysUsedAt
    riskPaidUntil
   
  }
  companys (limit: 1000) {
    id
    name
    basicListed
   }
	}
`

// relatedCommunications
//params{
//  key
//  value
//}

const cancelCreditLife = gql`
	mutation($creditLifeId:  String!, $reason: String) {
		cancelCreditLife(creditLifeId: $creditLifeId, reason: $reason) {
      id
      policyStatus
      reason
      obligationInsurances {
        id
        package
        amount
        premiumAmount
        cancelledAt
        obligationId
				obligation {
          id
          externalId
					clientCreditor {
            id
						description
					}
					reference
          inceptionDate
					currentBalance
					insuranceAmount
				}
      }
		}
	}
`

const UPDATE_CREDIT_LIFE = gql`
	mutation UpdateCreditLife($id:  String!, $input: UpdateCreditLifeInput! ) {
		updateCreditLife(id: $id, input: $input) {
      id
      paymentBankAccount {
      id
      bankName
      branchCode
      accountNo
      ownAccount
      accountHolderName
      accountHolderContactNumber
      accountHolderIdNumber
      accountHolderEmail
      idNumberMatch
      nameMatch
      phoneMatch
      accountExists
      avsRawData
    }
    secondaryApplicantId
    }
	}
`
const EditModal = (props) => <FormModal componentProps={props} />

export const Smoker = ({ smoker, onSmokerChange }) => {
  return (
    <Selector
      options={['Yes', 'No', '?']}
      selected={smoker != null ? (smoker !== false ? 'Yes' : 'No') : '?'}
      onChange={onSmokerChange}
    />
  )
}
let CreditLife = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const { data, client: apolloClient, history, match, cancel } = props || {}
  const { creditlife, companys } = data || {}
  const { clientId, client, policyStatus: startStatus, paymentBankAccount, termsVersion: startTermsVersion, policyNumber, reason, updatedAt, riskPaidUntil } = creditlife || {}
  const { employers } = client || {}

  const [termsVersion, setTermsVersion] = useState(startTermsVersion || 'CORE')
  const [basicListed, setBasicListed] = useState()
  const [policyStatus, setPolicyStatus] = useState(startStatus || '')
  const [bankAccount, setBankAccount] = useState(paymentBankAccount)
  const [modal2Visible, setModal2Visible] = useState(false)
  const [declinedReason, setDeclinedReason] = useState()
  const [snackOpen, setSnackOpen] = useState(false)
  const [snackText, setSnackText] = useState()
  const [severity, setSeverity] = useState('success')

  useEffect(() => {
    setPolicyStatus(startStatus)
    setBasicListed(employers && employers[0] ? onBasicList(searchList(companys)) : false)
  }, [creditlife]);

  useEffect(() => {
    setBankAccount({ ...bankAccount, ...paymentBankAccount })
  }, [paymentBankAccount]);

  const backToList = () => {
    if (props.cancel) { props.cancel() }
    else { props.history.push('/credit-life') }
  }


  const CancelPolicy = ({ policyStatus, setModal2Visible, backToList }) => {
    const enabled = (['OPEN_MARKET', 'RMA'].includes(props.data.creditlife.productType) || (props.data.creditlife.client && props.data.creditlife.client.debtCounselling && props.data.creditlife.client.debtCounselling.proposalStatus === 'create')) ? true : false
    return (
    <>
      <Grid container className={classes.buttonRow}>
        <Grid item xs={3}><Button color="primary" onClick={() => backToList()}>Back to List</Button></Grid>
          <Grid item xs={3}><Button color="secondary" disabled={!enabled || policyStatus && (policyStatus.indexOf('TERMINATE') > -1 || policyStatus.indexOf('DECLINE') > -1)} onClick={() => setModal2Visible(true)}>Cancel Policy</Button></Grid>
      </Grid>
    </>
    )
  }

  const ReinstatePolicy = ({ confirmReinstate, backToList }) => (
    <Card>
      <Grid container className={classes.buttonRow}>
        <Grid item xs={3}><Button color="primary" onClick={() => backToList()}>Back to List</Button></Grid>
        <Grid item xs={3}><Button color="secondary" onClick={() => confirmReinstate()}>Reinstate</Button></Grid>
      </Grid>
    </Card>
  )

  const BackToList = ({ backToList }) => (
    <Card>
      <Grid container className={classes.buttonRow}>
        <Grid item xs={3}><Button color="primary" onClick={() => backToList()}>Back to List</Button></Grid>
      </Grid>
    </Card>
  )

  const reinstatePolicy = () => {
    apolloClient
      .mutate({
        mutation: UPDATE_CREDIT_LIFE,
        variables: {
          input: {
            policyStatus: "APPLICATION",
            reason: "reinstated"
          },
          id: creditlife.id,
        }
      })
      .then(() => {
        backToList()
      }
    )
  }

  const confirmReinstate = () => {
    confirm({
      title: 'Reinstate the Policy?',
      cancellationButtonProps: { variant: 'contained', color: 'default' },
      confirmationButtonProps: { variant: 'contained', color: 'primary' }
    }).then(() => {
      reinstatePolicy()
    })
  }

  const confirmProductType = (productType) => {
    if (policyStatus != "APPLICATION") {
      confirm({
        title: 'Are you really really sure?',
        description: 'This will move the policy to a QUOTE state.',
        cancellationButtonProps: { variant: 'contained', color: 'default' },
        confirmationButtonProps: { variant: 'contained', color: 'primary' }
      }).then(() => {
        setProductType(productType)
      })
    }
    else {
      setProductTypeNoStatusChange(productType)
    }
  }

  const setProductType = (productType) => {
    apolloClient
      .mutate({
        mutation: UPDATE_CREDIT_LIFE,
        variables: {
          input: {
            policyStatus: "QUOTE",
            reason: "Product type changed",
            productType
          },
          id: props.data.creditlife.id,
          paymentBankAccount: bankAccount
        }
      })
      .then(() => {
        setSnackText('Product Type updated')
        setSeverity('success')
        setSnackOpen(true)
      }
    )
  }

  const setSecondaryApplicant = (secondaryApplicantId) => {
    apolloClient
      .mutate({
        mutation: UPDATE_CREDIT_LIFE,
        variables: {
          input: {
            secondaryApplicantId: secondaryApplicantId
          },
          id: props.data.creditlife.id,
        }
      })
  }

  const setProductTypeNoStatusChange = (productType) => {
    apolloClient
      .mutate({
        mutation: UPDATE_CREDIT_LIFE,
        variables: {
          input: {
            productType
          },
          id: props.data.creditlife.id,
          paymentBankAccount: bankAccount
        }
      })
      .then(() => {
        setSnackText('Product Type updated')
        setSeverity('success')
        setSnackOpen(true)
      }
      )
  }

  const updateCreditLife = (input) => {
    setSnackText('Changing the bank, wait for updated notification')
    setSeverity('info')
    setSnackOpen(true)

    apolloClient.mutate({
      mutation: UPDATE_CREDIT_LIFE,
      variables: {
        id: props.data.creditlife.id,
        input
      },
      refetchQueries: [{ query: creditLifeInformation, variables: { id: props.data.creditlife.id } }]
    })
      .then((response) => {
        setBankAccount(response.data.updateCreditLife.paymentBankAccount)
        setSnackText('Bank updated, screen will refresh')
        setSeverity('success')
        setSnackOpen(true)
      })
  }

  const cancelThePolicy = (declinedReason) => {
    if (declinedReason == null) { declinedReason = 'Client Affordability' } else { declinedReason = '' }
    if (['OPEN_MARKET', 'RMA'].includes(props.data.creditlife.productType) || (props.data.creditlife.client && props.data.creditlife.client.debtCounselling && props.data.creditlife.client.debtCounselling.proposalStatus === 'create')) {

      setSnackText('cancelling ...')
      setSeverity('info')
      setSnackOpen(true)

      apolloClient
        .mutate({
          mutation: cancelCreditLife,
          variables: {
            creditLifeId: props.data.creditlife.id,
            reason: declinedReason || 'Client Affordability'
          }
        })
        .then(() => {
          setSnackText('Policy cancelled')
          setSeverity('success')
          setSnackOpen(true)
          backToList()
        }
      )
    }
    else {
      confirm({
        title: 'Cancel not Possible',
        description: 'Only policies with DC Proposal Status of "create" can be cancelled',
        cancellationButtonProps: { variant: 'contained', color: 'default' },
        confirmationButtonProps: { variant: 'contained', color: 'primary' }
      })
    }
  }

  const cardTitle = (creditlife) => {
    const { policyNumber, policyStatus, reason, updatedAt, riskPaidUntil } = creditlife || {}

    return `Credit Life Policy {policyNumber} <strong>{policyStatus}</strong> ${reason ? '(' + reason + ')' : ''}  Last Update: ${updatedAt ? moment(updatedAt).format('H:mm on DD-MM-YYYY') : ''}  Risk Paid Until: ${moment(riskPaidUntil).format('DD-MM-YYYY')}`
  }


  if (!creditlife) return <h2>Please reload</h2>

  if (data.loading) {
    return <ActivityIndicator center={false} />
  }

  else return (
    <Fragment>
      <Card >
        <Grid container className={classes.root} spacing={3}>
          <Grid item >Credit Life Policy <strong>{policyNumber}</strong></Grid>
            <Grid item style={{ fontWeight: "bold" }}>*** {policyStatus} {reason ? '(' + reason + ')' : ''}***</Grid>
          <Grid item>Last Updated: {updatedAt ? moment(updatedAt).format('H:mm on DD-MM-YYYY') : ''} </Grid>
          <Grid item >{riskPaidUntil ? `Risk Paid Until: ${moment(riskPaidUntil).format('DD-MM-YYYY')}` : ''}</Grid>
        </Grid>

        <ClientInformation id={creditlife.client.id} refetchQuery={creditLifeInformation} cancel={cancel} history={history} creditLife={creditlife.id} productId={creditlife.id} />
        <br />
        {creditlife.secondaryApplicantId 
          ? <ClientInformation title="Spouse" id={creditlife.secondaryApplicantId} refetchQuery={creditLifeInformation} cancel={cancel} history={history} creditLife={creditlife.id} productId={creditlife.id} />
          : <EditModal apolloClient={apolloClient} Content={Client} setSecondaryApplicant={setSecondaryApplicant} displayItem={false} mode={'add'} icon={"add"} text={"Add Secondary Applicant"}  heading={"Add Secondary Applicant"} id={creditlife.client.id} noButton={true} />
        } 
        <br />
        <br />
        <Information
          id={creditlife.id}
          clientData={client}
          employer={employers && employers[0]}
          employmentStatus={client.employmentStatus}
          debtCounselling={client.debtCounselling}
          actionDay={creditlife.actionDay}
          paymentDay={creditlife.paymentDay}
          nextOfKin={creditlife.beneficiaries ? creditlife.beneficiaries[0] : undefined}
          policyStatus={policyStatus}
          policyNumber={creditlife.policyNumber}
          riskPaidUntil={creditlife.riskPaidUntil}
          reason={creditlife.reason}
          updatedAt={creditlife.updatedAt}
          history={history}
          basicListed={basicListed}
          setBasicListed={setBasicListed}
          companys={data.companys}
          client={apolloClient}
          productType={creditlife.productType}
          setProductType={confirmProductType}
          cancel={props.cancel}
        />
        <br />

        {policyStatus !== "APPLICATION" && <>
          <PolicyInformation creditlife={creditlife} setProductType={confirmProductType} />
          <LoanObligations termsVersion={creditlife.termsVersion} creditlife={creditlife} loans={creditlife.obligationInsurances} obligations={client.creditorObligations.filter(o => (creditlife.productType === "OPEN_MARKET" ? o.source === "COMPUSCAN" : (o.source === "SIMPLICITY" || !o.source)))} proposalStatus={client.debtCounselling && client.debtCounselling.proposalStatus} basicListed={basicListed} />

          {/* {["QUOTE", "QUOTE_UPDATE", "QUOTE_EXISTING_BUSINESS"].indexOf(policyStatus) > -1 ? */}
          {["QUOTE"].indexOf(policyStatus) > -1 ?
            <MissingRequirements creditlife={creditlife} backToList={backToList} productType={creditlife.productType} setProductType={confirmProductType} setPolicyStatus={setPolicyStatus} updateProduct={updateCreditLife} />

            : ["MATURED", "QUOTE", "QUOTE_UPDATE", "QUOTE_EXISTING_BUSINESS", "DECLINED_APPLICATION", "DECLINED_QUOTE", "TERMINATED_CLIENT_REQUESTED", "TERMINATED_NO_PAYMENT"].indexOf(policyStatus) === -1 ?
              <CancelPolicy policyStatus={policyStatus} setModal2Visible={setModal2Visible} backToList={backToList} />

              : ["DECLINED_APPLICATION", "DECLINED_QUOTE", "TERMINATED_CLIENT_REQUESTED", "TERMINATED_NO_PAYMENT"].indexOf(policyStatus) > -1 ?
                <ReinstatePolicy confirmReinstate={confirmReinstate} backToList={backToList} />

                : policyStatus && policyStatus.indexOf("DECLINED_QUOTE_XXXXXX") > -1 ?
                  <BackToList backToList={backToList} />
                  :
                  <div />}
        </>}
      </Card>

      {policyStatus === "APPLICATION" &&
        <>
        {/* <SelectProductType productType={creditlife.productType} setProductType={confirmProductType} acceptedTerms={client.acceptedTerms} hasSignedDO={creditlife.hasSignedDO} /> */}
          <CreditLifeQuote
            loans={client.creditorObligations.filter(o => !o.removedAt && (creditlife.productType === "OPEN_MARKET" ? o.source === "COMPUSCAN" : (o.source === "SIMPLICITY" || !o.source)))}
            id={match.params.id}
            employer={employers && employers[0]}
            employmentStatus={client.employmentStatus}
            smoker={client.smoker}
            clientId={client.id}
            idNumber={client.idNumber}
            idType={client.idType}
            acceptedTerms={client.acceptedTerms}
            history={history}
            basicListed={basicListed}
            productType={creditlife.productType}
            termsVersion={creditlife.termsVersion}
            pricingVersion={creditlife.pricingVersion}
            creditLifeId={creditlife.id}
            setStatus={setPolicyStatus}
            client={apolloClient}
          />
        </>}

      {(creditlife.productType === "OPEN_MARKET" || creditlife.productType === "CREDIT_PROVIDER" || creditlife.productType === "RMA") && ["QUOTE", "QUOTE_UPDATE", "QUOTE_EXISTING_BUSINESS", "APPLICATION"].indexOf(policyStatus) === -1 &&
        <BankAccount {...creditlife} paymentBankAccount={paymentBankAccount} clientId={creditlife.client.id} productId={creditlife.id} productType="CREDIT_LIFE" updateProduct={updateCreditLife} />

      }

      {policyStatus !== "APPLICATION" && policyStatus !== "QUOTE" && <>
          <br />
          <Grid container justifyContent='flex-start' spacing={1}>
            <Grid item xs={6}> <PaymentHistory history={creditlife.payments} /></Grid>
            <Grid item xs={6}><PolicyStatusHistory history={creditlife.policyStatusChanges} /></Grid>
          </Grid>

          {creditlife.allocationSummary && <Grid container justifyContent='flex-start' alignItems='center' spacing={1}>
            <Grid item> <AllocationSummary summary={creditlife.allocationSummary} product={"CREDIT_LIFE"} /></Grid>
          </Grid>}
          <GraceDays creditlife={creditlife} />
        </>
      }
      
      <ClientNotes clientId={clientId} productId={creditlife.id} type={"Policy"} />
      <ProductCommunication clientId={client.id} productId={creditlife.id} type={"CREDITLIFE"} />
      <ClientDocuments clientId={clientId} />

      <Modal
        title="Policy Cancellation"
        dialogText="Do you want to Cancel the Policy?"
        okText='Yes, Cancel Policy'
        cancelText='No, Go Back!'
        wrapClassName="vertical-center-modal"
        open={modal2Visible}
        onOk={() => {
          setModal2Visible(false)
          cancelThePolicy(declinedReason)
        }}
        onCancel={() => setModal2Visible(false)}
        onClose={() => setModal2Visible(false)}
      >
        Why are you rejecting this?
        <Select
          label="Cancellation reason"
          style={{ width: 200 }}
          options={rejectReasons}
          onChange={(v) => setDeclinedReason(v.target.value)}
          placeholder="Select a reason"
          value={declinedReason}
        />
      </Modal>

      <Snackbar open={snackOpen} autoHideDuration={6000} onClose={() => setSnackOpen(false)}>
        <Alert onClose={() => setSnackOpen(false)} severity={severity}>
          {snackText}
        </Alert>
      </Snackbar>

    </Fragment>
  )
}

const rejectReasons = [{ value: "Client Affordability", label: "Client Affordability" }, { value: "Client terminated in DC", label: "Client terminated in DC" }, { value: "Below minimum premium", label: "Below minimum premium" }, { value: "Client changed their mind", label: "Client changed their mind" }, { value: "Client Age", label: "Client Age" }, { value: "Other", label: "Other" }]

const graphqlLoadingHOC = Comp => props => {

  if (props.data.loading) {
    return <ActivityIndicator />
  }

  if (props.data.error) {
    return <h1>{props.data.error.toString()}</h1>
  }

  //console.log('CreditLife graphqlLoadingHOC props ', props.data)

  return <Comp {...props} />
}

CreditLife = withRouter(CreditLife)
CreditLife = withApollo(CreditLife)
CreditLife = graphqlLoadingHOC(CreditLife)
CreditLife = graphql(creditLifeInformation, {
  options: ({ match, id }) => ({ variables: { id: match && match.params ? match.params.id : id } })
})(CreditLife)

export default CreditLife